.cm_color-slider {
  margin: 5px 0;
  position: relative;
  height: 24px;
}
  .cm_color-slider.light .cm_mark {
      box-shadow: 0 0 0 2px rgb(221, 221, 221);
      background-color: rgb(221, 221, 221);
    }
  .cm_color-slider.light .cm_strip.selected,
    .cm_color-slider.light .color-tile {
      border: solid 1px rgb(221, 221, 221);
    }
  .cm_color-slider > div {
    display: inline-block;
    position: absolute;
  }
  .cm_color-slider .discard {
    top: 4px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  .cm_color-slider .discard:before {
      content: '[x]';
    }
  .cm_color-slider .strip {
    left: 16px;
    right: 16px;
    padding: 0 5px;
  }
  .cm_color-slider .color-tile {
    right: 0;
    top: 4px;
    width: 16px;
    height: 16px;
  }
