.cm_strip-container {
    height: calc(12px * 2);
    margin: 0 12px;
    position: relative;
    cursor: pointer;
    touch-action: pan-y pinch-zoom;
  }
  .cm_strip {
    height: 4px;
    background-color: rgb(197, 197, 197);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
  }
  .cm_strip.selected {
    background-color: rgb(34, 48, 58);
  }

.cm_mark-container {
    position: absolute;
    top: 0;
    bottom: 0;
  }

.cm_mark {
    box-sizing: border-box;
    width: calc(12px * 2);
    height: calc(12px * 2);
    border: 8px solid rgb(34, 48, 58);
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    position: absolute;
    bottom: 0;
    left: -12px;
  }
