.cm_dropdown {
  z-index: 1001;
  border: 1px solid rgb(218, 218, 218);
  background-color: rgb(236, 236, 236);
  position: absolute;
  left: 0;
  right: 0;
}
  .cm_dropdown .option {
    display: flex;
    cursor: default;
    align-items: baseline;
    padding: 0 5px;
  }
  .cm_dropdown .option:hover,
    .cm_dropdown .option.cm_checked {
      background-color: rgb(204, 204, 204);
    }
  .cm_dropdown_checkbox {
    flex: none;
    margin: 0 5px 0 0;
  }
  .cm_dropdown_value {
    flex: 1;
  }
  .cm_dropdown_hitCount {
    flex: none;
    margin: 0 0 0 5px;
  }
  .cm_dropdown_hitCount:before {
      content: '(';
    }
  .cm_dropdown_hitCount:after {
      content: ')';
    }
