.cm_load-more {
  text-align: center;
  position: relative;
  margin-top: 30px;
}
  .cm_load-more .cm_loading {
    position: static;
    background-color: transparent;
  }
  .cm_load-more > div {
    cursor: pointer;
  }
  .cm_load-more:empty {
    display: none;
  }
