.cm_slider-container {
    position: relative;
    height: calc(24px + calc(12px * 2));
  }
    .cm_slider-container .cm_strip-container {
      top: 24px;
    }
  .cm_slider-title {
    border: 2px solid rgb(34, 48, 58);
    border-radius: 10px;
    padding: 0 2px;
    position: absolute;
    opacity: 0;
    transition: opacity 1s linear;
  }
  .cm_slider-title.visible {
      opacity: 1;
    }
.cm_slider-bounds {
  color: rgb(153, 153, 153);
  cursor: default;
}
.cm_slider-bounds .min {
    float: left;
  }
.cm_slider-bounds .max {
    float: right;
  }
