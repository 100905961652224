.color-tile {
  display: inline-block;
  box-sizing: border-box;
}
  .color-tile.selected {
    position: relative;
  }
  .color-tile.selected:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-image: url('https://client.convermax.com/static/img/color/check.png');
      background-color: rgb(234, 234, 234);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0.3;
    }
  .color-tile.show-swatch {
    position: relative;
  }
  .color-tile.show-swatch:before,
    .color-tile.show-swatch:after {
      content: '';
      box-sizing: border-box;
      position: absolute;
      display: block;
      z-index: 2;
      transition: opacity 700ms;
      opacity: 0;
      pointer-events: none;
    }
  .color-tile.show-swatch:hover:before,
      .color-tile.show-swatch:hover:after {
        opacity: 1;
      }
  .color-tile.show-swatch:before {
      width: 100px;
      height: 100px;
      bottom: calc(-100px - 10px);
      border: 1px solid rgb(170, 170, 170);
      border-radius: 6px;
      background-color: rgb(255, 255, 255);
    }
  .color-tile.show-swatch:after {
      width: calc(100px - 7px * 2);
      height: calc(100px - 7px * 2);
      bottom: calc(-100px - 10px + 7px);
      left: 7px;
      background-color: inherit;
    }
